<script setup lang="ts">
import { add, sub } from 'date-fns';
import { graphRunFilters } from '~/util/filters';

const liveQuery = ref('');
const searchQuery = ref('');
const dateRange = ref({
  start: sub(new Date(), { days: 14 }),
  end: add(new Date(), { days: 1 }),
});

const filters = ref({
  state: [],
  type: [],
  q: searchQuery,
  dateRange,
});

const filterLabel = (filter) => {
  const selectedFilters = filters.value[filter.key];

  switch (selectedFilters.length) {
    case 0:
      return filter.name;
    case 1:
      return selectedFilters[0]?.name;
    default:
      return `${filter.name}: (${selectedFilters.length})`;
  }
};

const clear = () => {
  liveQuery.value = '';
  searchQuery.value = '';
};

const debouncedSearch = useDebounceFn(
  (query: string) => {
    searchQuery.value = query;
  },
  300,
  { maxWait: 500 },
);
</script>
<template>
  <NuxtLayout name="dashboard">
    <div class="column flex is-left">
      <p class="main-title">History</p>
    </div>
    <div class="py-6 pb-6 top-0 left-0 flex flex-row space-x-3 justify-start">
      <div v-for="filter in graphRunFilters" :key="filter.key">
        <USelectMenu
          v-model="filters[filter.key]"
          :options="filter.options"
          :popper="{ placement: 'bottom' }"
          multiple
        >
          <UButton
            size="xl"
            truncate
            class="flex-1"
            :color="filters[filter.key].length ? 'primary' : 'gray'"
            :ui="{
              rounded: 'rounded-full',
              base: filters[filter.key].length > 0 ? 'shrink-0' : 'shrink',
            }"
            :variant="filters[filter.key].length > 0 ? 'solid' : 'outline'"
          >
            <UIcon
              v-if="filters[filter.key].length === 1"
              :name="filters[filter.key][0].icon"
              class="text-2xl"
            />
            <span class="truncate">
              {{ filterLabel(filter) }}
            </span>
            <UIcon
              :name="
                filters[filter.key].length > 0
                  ? 'i-ph-caret-down-bold'
                  : 'i-ph-caret-down'
              "
            />
          </UButton>

          <template #option="{ option: category }">
            <span class="flex gap-1">
              <UIcon :name="category.icon" />
              <span class="truncate">{{ category.name }}</span>
            </span>
          </template>
        </USelectMenu>
      </div>
      <AppCalendar v-model="dateRange" type="daterange" class="shrink-0" />
      <div class="w-full">
        <UInput
          v-model="liveQuery"
          icon="i-ph-magnifying-glass-bold"
          color="white"
          variant="outline"
          class="grow"
          size="lg"
          placeholder="Search your spells..."
          :ui="{ icon: { trailing: { pointer: '' } } }"
          @input="debouncedSearch($event.target.value)"
        >
          <template #trailing>
            <UButton
              v-show="liveQuery !== ''"
              color="gray"
              variant="link"
              icon="i-ph-x"
              :padded="false"
              @click="clear"
            />
          </template>
        </UInput>
      </div>
    </div>

    <HistoryTable :filters="filters" :page-size="10" />
  </NuxtLayout>
</template>
